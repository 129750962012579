import React from "react"
import "./Token.css"
import token from "../assets/token.png"

const Token = () => {
  return (
    <div className="wrapper3">
      <div className="cover"></div>
      <div className="covertext">Coming soon</div>
      <div className="title">KAW Token</div>
      <div className="container">
        <div className="contentcontainer">
          <div className="text">
            Want a piece of the action? Buy KAW Token right now
          </div>
          <div className="button">Become an investor</div>
        </div>
        <div className="imagecontainer">
          <img src={token} className="tokenimage" />
        </div>
      </div>
    </div>
  )
}

export default Token
