import React from "react"
import Header from "../components/Header"

const Contact = () => {
  return (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          fontFamily: "Zen Dots",
        }}
      >
        <p>
          <a
            style={{ textDecoration: "none", color: "black" }}
            href="mailto:contact@kawindustries.com"
          >
            contact
          </a>
        </p>
      </div>
    </div>
  )
}

export default Contact
