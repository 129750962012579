import { Canvas, useFrame, useLoader } from "@react-three/fiber"
import { OrbitControls, Stars } from "@react-three/drei"
import React from "react"
import { TextureLoader } from "three/src/loaders/TextureLoader"
import front from "../assets/Front.jpg"
import back from "../assets/Back.jpg"
import longB from "../assets/LongBottom.jpg"
import longT from "../assets/LongTop.jpg"
import shortL from "../assets/ShortL.jpg"
import shortR from "../assets/shortR.jpg"
import backInv from "../assets/BackInv.jpg"
import logo from "../assets/Logo.jpg"
import { BrowserRouter, Route, Link } from "react-router-dom"
import Header from "../components/Header"
import Main from "../components/Main"
import Products from "../components/Products"
import Stats from "../components/Stats"
import Token from "../components/Token"

function App() {
  return (
    <>
      <Header />
      <Main />
      <Products />
      <Stats />
      <Token />
    </>
  )
}

export default App
