import React from "react"
import ReactDOM from "react-dom/client"
import "./global.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import About from "./pages/About"
import Contact from "./pages/Contact"
import Home from "./pages/Home"
import { Route, Link } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
