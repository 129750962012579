import React from "react"
import "./Products.css"
import guardians from "../assets/guardians.png"
import hg from "../assets/hg.png"
import survey from "../assets/survey.png"
import trender from "../assets/trender.png"
import Marquee from "react-fast-marquee"

const Products = () => {
  return (
    <div className="wrapper">
      <div className="textcontainer">OUR PRODUCTS</div>
      <div className="abz">
        {/* <div class="slider-container">
          <div class="slider-track"> */}
        <Marquee gradient={false} speed={125}>
          <img src={hg} class="slider-item" alt="historyguessr" />
          <img src={guardians} class="slider-item" alt="crypto guardians" />
          <img src={trender} class="slider-item" alt="trender app" />
          <img src={survey} class="slider-item" alt="survey protocol" />
          <img src={hg} class="slider-item" alt="historyguessr" />
          <img src={guardians} class="slider-item" alt="crypto guardians" />
          <img src={trender} class="slider-item" alt="trender app" />
          <img src={survey} class="slider-item" alt="survey protocol" />
        </Marquee>
        {/* </div>
        </div> */}
      </div>
    </div>
  )
}

export default Products
