import React, { useState, useEffect } from "react"
import "./Stats.css"

const Stats = () => {
  const [clients, setClients] = useState("0")
  const [products, setProducts] = useState("0")
  const [activeUsers, setActiveUsers] = useState("0")
  const [treasury, setTreasury] = useState("0")
  const [revenue, setRevenue] = useState("0")

  useEffect(() => {
    const intervalId = setInterval(() => {
      setClients(Math.floor(Math.random() * 100))
      setProducts(Math.floor(Math.random() * 50))
      setActiveUsers(Math.floor(Math.random() * 10000))
      setTreasury(Math.floor(Math.random() * 10000))
      setRevenue(Math.floor(Math.random() * 5000))
    }, 100)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className="wrapper2">
      <div className="textcontainer2">KAW Industries right now </div>
      <div className="statscontainer">
        <div className="stat" key="activeUsers">
          <div className="statnumber">{activeUsers}+</div>
          <div className="stattitle">Active Users</div>
        </div>
        <div className="stat" key="products">
          <div className="statnumber">{products}</div>
          <div className="stattitle">Products</div>
        </div>
        <div className="stat" key="clients">
          <div className="statnumber">{clients}</div>
          <div className="stattitle">Clients</div>
        </div>

        <div className="stat" key="revenue">
          <div className="statnumber">{revenue}$</div>
          <div className="stattitle">Monthly Revenue</div>
        </div>
      </div>
    </div>
  )
}

export default Stats
