import React from "react"
import logo from "../assets/Logo.jpg"
import { Link } from "react-router-dom"

const Header = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Link
          to="/"
          style={{
            fontFamily: "Zen Dots",
            textDecoration: "none",
            color: "black",
            fontSize: 40,
          }}
        >
          {/* <img src={logo} style={{ width: 100, height: 61, marginTop: 0 }} /> */}
          KAW
        </Link>
      </div>
      <div className="navbar">
        <Link to="/contact" className="headeritem">
          CONTACT
        </Link>
        <Link to="/about" className="headeritem">
          ABOUT
        </Link>
      </div>
    </div>
  )
}

export default Header
