import React from "react"
import Header from "../components/Header"

const About = () => {
  return (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          style={{
            width: "50%",
            textAlign: "center",
            // fontFamily: "VT323",
            fontFamily: "Zen Dots",
          }}
        >
          <p>
            Kaw Industries has been solving problems since 2022. We focus on
            creating long lasting value that will stand the test of time. We
            know that companies will have to adapt to the future tech landscape
            with digital ownership and privacy, this is where we thrive. Contact
            us for consulting or development.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
