import { Canvas, useFrame, useLoader } from "@react-three/fiber"
import { OrbitControls, Stars } from "@react-three/drei"
import React from "react"
import { TextureLoader } from "three/src/loaders/TextureLoader"
import front from "../assets/Front.jpg"
import back from "../assets/Back.jpg"
import longB from "../assets/LongBottom.jpg"
import longT from "../assets/LongTop.jpg"
import shortL from "../assets/ShortL.jpg"
import shortR from "../assets/shortR.jpg"
import backInv from "../assets/BackInv.jpg"
import logo from "../assets/Logo.jpg"

import { BrowserRouter, Route, Link } from "react-router-dom"
import Header from "../components/Header"

function MyRotatingBox() {
  const myMesh = React.useRef()
  const [map1, map2, map3, map4, map5, map6] = useLoader(TextureLoader, [
    front,
    backInv,
    longB,
    longT,
    shortL,
    shortR,
  ])

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime()
    myMesh.current.rotation.y = a
  })

  return (
    <mesh ref={myMesh}>
      <boxBufferGeometry args={[6, 3, 0.5]} />
      <meshStandardMaterial attach="material-0" map={map5} />
      <meshStandardMaterial attach="material-1" map={map6} />
      <meshStandardMaterial attach="material-2" map={map4} />
      <meshStandardMaterial attach="material-3" map={map3} />
      <meshStandardMaterial attach="material-4" map={map1} />
      <meshStandardMaterial attach="material-5" map={map2} />
    </mesh>
  )
}

function Main() {
  return (
    <div className="wrapperMain">
      <div className="textContainer">
        <p>We create, distribute and encourage innovative tech </p>
      </div>
      <div className="animationContainer">
        <Canvas camera={{ position: [0, 0, 6] }} shadowMap>
          {/* <Canvas1
          orthographic
          camera={{ left: -2, right: 2, top: 2, bottom: -2, zoom: 90 }}
        > */}
          {/* <OrbitControls enableZoom={false} /> */}
          <ambientLight intensity={0.3} />
          <pointLight position={[-1, -2, 10]} />

          <MyRotatingBox />
        </Canvas>
      </div>
    </div>
  )
}

export default Main
